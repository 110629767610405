import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  .project-page-container {
    max-width: 1190px;
    height: 100%;
    margin: 0 auto;
    padding-top: 30px;
    .project-page-header-container {
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      .project-name-container {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        font-size: 40px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-right: 35px;
        color: white;
      }
      .create-project-btn {
        width: 160px;
        font-size: 14px;
      }
    }
    .project-list-container {
      &.empty-list {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100% - 90px);
        ${'' /* 90px from 35 margin-bottom and content 55px */}
      }
      .empty-project-container {
        width: 250px;
        .empty-project-image {
          display: flex;
          justify-content: center;
          margin-bottom: 24px;
          img {
            width: 50px;
            height: 37px;
            object-fit: contain;
          }
        }
        .empty-project-message {
          font-family: 'Prompt-Bold', sans-serif;
          font-size: 30px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: white;
          text-align: center;
        }
      }
    }
  }
`
