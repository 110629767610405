const MESSAGE = {
  EN: {
    page_title: 'Project Management',
    add_new_project_btn_txt: 'Add New Project',
    empty_case_txt: 'Nothing to Show',
    create_btn_txt: 'Create',
    dialog_create_project_label_txt: 'PROJECT NAME',
    dialog_create_project_placeholder_txt: 'Project name',
    dialog_create_project_error_txt: 'Invalid name.',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again'
  },
  TH: {
    page_title: 'จัดการโปรเจกต์',
    add_new_project_btn_txt: 'สร้างโปรเจกต์ใหม่',
    empty_case_txt: 'ไม่มีข้อมูล',
    create_btn_txt: 'สร้าง',
    dialog_create_project_label_txt: 'ชื่อโปรเจกต์',
    dialog_create_project_placeholder_txt: 'ชื่อ',
    dialog_create_project_error_txt: 'ชื่อไม่ถูกต้อง',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง'
  }
}

export default MESSAGE
