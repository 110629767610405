/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as ProjectActions from './actions'

import Button from '../../components/Button/Loadable'
import ProjectManagementCard from '../../components/ProjectManagementCard/Loadable'
import ModalOverlay from '../../components/ModalOverlay/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import Spinner from '../../components/Spinner/Loadable'
import TextInput from '../../components/TextInput/Loadable'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

import ProjectPageLayoutStyled from './styledComponent'

import Image from '../../asset/images/default.png'
import emptyCaseImg from '../../asset/images/emptyCase.svg'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE, FRONTEND_ROUTES } from '../../utils'

import MESSAGE from './message'

export class ProjectPageLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      newProjectName: '',
      isProjectNameValid: undefined,
      showCreateNewProjectModal: false,
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    }
  }

  componentDidMount() {
    this.props.loadProjectList()
  }

  componentDidUpdate(prevProps, prevState) {
    this._handleFetchLoadProjectList(prevProps)
    this._handleFetchCreateProject(prevProps)
    this._checkPermission()
  }

  _checkPermission() {
    const isAdmin = this.props.currentUser.admin
    const isManager = this.props.currentUser.manager
    const isUser = !(isAdmin || isManager)
    if (isUser) {
      this.props.history.push(FRONTEND_ROUTES.selectSiteRoute)
    }
  }

  _handleFetchLoadProjectList(prevProps) {
    if (
      prevProps.projectPage.loadProjectList.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectPage.loadProjectList.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectPage.loadProjectList.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectPage.loadProjectList.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.setShowLoading(false)
    }
    if (
      prevProps.projectPage.loadProjectList.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectPage.loadProjectList.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleLoadProjectListFailure()
    }
  }

  _handleFetchCreateProject(prevProps) {
    if (
      prevProps.projectPage.createProject.fetchStatus === FETCH_STATUS_IDLE &&
      this.props.projectPage.createProject.fetchStatus === FETCH_STATUS_REQUEST
    ) {
      this.setShowLoading(true)
    }
    if (
      prevProps.projectPage.createProject.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectPage.createProject.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      this.handleCreateProjectSuccess()
    }
    if (
      prevProps.projectPage.createProject.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.projectPage.createProject.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.setShowLoading(false)
      this.handleCreateProjectFailure()
    }
  }

  handleLoadProjectListFailure() {
    const statusCode = this.props.projectPage.loadProjectList.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  handleCreateProjectFailure() {
    const statusCode = this.props.projectPage.createProject.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: this.forceLogin
      },
      this.showDefaultFailureModal
    )
  }

  handleCreateProjectSuccess() {
    const { createdProject } = this.props.projectPage.createProject
    this.props.history.push(`${FRONTEND_ROUTES.projectManagementRoute}/${createdProject.project_id}`)
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  forceLogin = () => {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  setShowLoading(status) {
    this.setState({
      isLoading: status
    })
  }

  handleCreateNewProjectClicked = () => {
    this.setState({
      newProjectName: '',
      showCreateNewProjectModal: true,
      isProjectNameValid: undefined
    })
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser.language]
    return msg[property]
  }

  getHeaderProjectManagementPage() {
    const isAdmin = this.props.currentUser.admin
    let createProjectBtn = null
    if (isAdmin) {
      createProjectBtn = (
        <Button className="create-project-btn" onClick={this.handleCreateNewProjectClicked} text={this.getMessage('add_new_project_btn_txt')} />
      )
    }
    return (
      <div className="project-page-header-container">
        <div className="project-name-container">{this.getMessage('page_title')}</div>
        {createProjectBtn}
      </div>
    )
  }

  handleEditClicked = (projectID) => {
    this.props.history.push(`${FRONTEND_ROUTES.projectManagementRoute}/${projectID}`)
  }

  renderEmptyProject() {
    return (
      <div className="empty-project-container">
        <div className="empty-project-image">
          <img src={emptyCaseImg} alt="" />
        </div>
        <div className="empty-project-message">{this.getMessage('empty_case_txt')}</div>
      </div>
    )
  }

  getListProjectManagementCard() {
    let output = this.renderEmptyProject()
    if (this.props.projectPage.listProject.length > 0) {
      output = this.props.projectPage.listProject.map((projectData) => {
        const image = projectData.pre_encode_image || Image
        return (
          <ProjectManagementCard
            key={projectData.project_id}
            projectID={projectData.project_id}
            screenshotImage={image}
            projectName={projectData.name}
            totalSites={projectData.sites.length}
            totalCameras={projectData.camera_count}
            onClick={this.handleEditClicked}
            language={this.props.currentUser.language}
          />
        )
      })
    }
    return output
  }

  getProjectListClassName() {
    let output = 'project-list-container'
    if (this.props.projectPage.listProject.length === 0) {
      output += ' empty-list'
    }
    return output
  }

  getListProject() {
    return <div className={this.getProjectListClassName()}>{this.getListProjectManagementCard()}</div>
  }

  getLoading() {
    let output = null
    if (this.state.isLoading) {
      output = (
        <ModalOverlay>
          <Spinner />
        </ModalOverlay>
      )
    }
    return output
  }

  _isProjectNameValid = () => {
    const name = this.state.newProjectName.trim()
    const isProjectNameValid = name.length > 0
    this.setState({ isProjectNameValid })
    return isProjectNameValid
  }

  handleCreateProjectBtnClicked = () => {
    if (this._isProjectNameValid()) {
      const name = this.state.newProjectName.trim()
      const data = {
        project: {
          name
        }
      }
      this.props.createProject(data)
      this.handleCloseCreateProjectModal()
    }
  }

  handleProjectNameChanged = (e, newValue) => {
    this.setState({
      newProjectName: newValue
    })
  }

  handleCloseCreateProjectModal = () => {
    this.setState({
      showCreateNewProjectModal: false,
      newProjectName: '',
      isProjectNameValid: false
    })
  }

  getProjectNameInputErrorText() {
    let output = null
    if (this.state.isProjectNameValid === false) {
      output = this.getMessage('dialog_create_project_error_txt')
    }
    return output
  }

  getCreateNewProjectModal() {
    let output = null
    if (this.state.showCreateNewProjectModal) {
      output = (
        <ModalOverlay onOverlayClicked={this.handleCloseCreateProjectModal} onClose={this.handleCloseCreateProjectModal}>
          <div className="modal-content-container" onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('dialog_create_project_label_txt')}
                  id="create-project-input"
                  placeHolder={this.getMessage('dialog_create_project_placeholder_txt')}
                  value={this.state.newProjectName}
                  onChange={this.handleProjectNameChanged}
                  errorText={this.getProjectNameInputErrorText()}
                />
              </div>
            </div>
            <Button
              className="full-width"
              id="create-project-save-btn"
              onClick={this.handleCreateProjectBtnClicked}
              text={this.getMessage('create_btn_txt')}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <ProjectPageLayoutStyled>
        <div className="project-page-container">
          {this.getHeaderProjectManagementPage()}
          {this.getListProject()}
        </div>
        {this.getCreateNewProjectModal()}
        {this.getLoading()}
        {this.getFailureModal()}
      </ProjectPageLayoutStyled>
    )
  }
}

ProjectPageLayout.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  projectPage: PropTypes.object.isRequired,
  loadProjectList: PropTypes.func.isRequired
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    currentUser: state.loginPage.loginAPI.userData,
    projectPage: state.projectPage
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, ProjectActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPageLayout)
